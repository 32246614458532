import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Construction: FunctionComponent<SvgProps> = (props) => (
  <Svg
    optimizationId="iconFamilyB2BConstruction"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#1E3C87"
      d="M2.554 12.418H9.03c.306 0 .554.248.554.554v2.947a.554.554 0 01-.554.553H2.554A.554.554 0 012 15.918v-2.946c0-.306.248-.554.554-.554z"
    />
    <path
      fill="#F0A22D"
      d="M13.7 8.263v2.947a.554.554 0 01-.554.554h-6.47a.554.554 0 01-.554-.554V8.263c0-.306.248-.554.553-.554h6.47c.307 0 .555.248.555.554z"
    />
    <path
      fill="#1E3C87"
      d="M14.913 7.709h6.476c.306 0 .554.248.554.554v2.947a.554.554 0 01-.554.554h-6.476a.554.554 0 01-.554-.554V8.263c0-.306.248-.554.554-.554zM17.821 3.554v2.947a.554.554 0 01-.554.554h-6.47a.554.554 0 01-.554-.554V3.554c0-.306.248-.554.554-.554h6.47c.306 0 .554.248.554.554zM17.821 12.971v2.947a.554.554 0 01-.554.554h-6.47a.554.554 0 01-.554-.554v-2.947c0-.306.248-.554.554-.554h6.47c.306 0 .554.248.554.554zM14.913 17.126h6.476c.306 0 .554.248.554.554v2.947a.554.554 0 01-.554.554h-6.476a.554.554 0 01-.554-.554V17.68c0-.306.248-.554.554-.554zM13.7 17.68v2.947a.554.554 0 01-.554.554h-6.47a.554.554 0 01-.554-.554V17.68c0-.306.248-.554.553-.554h6.47c.307 0 .555.248.555.554z"
    />
  </Svg>
);

Construction.displayName = 'Construction';
