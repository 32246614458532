import qs from 'qs';

import type { PlainQueryParams } from '../interfaces/algolia';

const ENCODED_AMPERSAND = encodeURIComponent('&');
const ENCODED_PLUS = encodeURIComponent('+');

export const replaceSpacing = (
  input: string | number,
  replaceValue = '+',
): string => `${input}`.replace(/ +|%20+/g, replaceValue);

const encodeAmpersand = (
  input: string | number,
  replaceValue = ENCODED_AMPERSAND,
): string => `${input}`.replace(/ +|&+/g, replaceValue);

const encodePlus = (
  input: string | number,
  replaceValue = ENCODED_PLUS,
): string => `${input}`.replace(/\+/g, replaceValue);

export const stringify = (
  queryParams: PlainQueryParams,
  addQueryPrefix = true,
): string =>
  qs.stringify(queryParams, {
    addQueryPrefix,
    encoder: (value, defaultEncoder, charset, type) => {
      let encodedValue = value;
      if (type === 'value') {
        encodedValue = encodePlus(encodedValue);
        encodedValue = replaceSpacing(encodedValue);
        encodedValue = encodeAmpersand(encodedValue);
      }

      return encodedValue;
    },
  });

export const replacePlusOperatorBySpace = (value: string) =>
  value.replace(/\+/g, ' ');
export const replaceMinusOperatorBySpace = (value: string) =>
  value.replace(/-/g, ' ');
export const replaceEncodedPlusOperatorBySpace = (value: string) =>
  value.replace(/%2B/g, ' ');
export const decodePlusOperator = (value: string) =>
  value.replace(/ +|%20+/g, '+');
export const decodePlus = (
  input: string | number,
  replaceValue = '+',
): string => `${input}`.replace(/%2B/g, replaceValue);

// Made for SEO purposes, we want to have a clean URL,
// so after encoding it we replace space encoding to make it more readable
export const customEncodeURIComponent = (query: string) =>
  replaceSpacing(encodeURIComponent(query));
export const sanitizeQuery = (query: string) => {
  try {
    return decodeURIComponent(query).replace(/\+/g, ' ').trim();
  } catch (error) {
    error.message = `Error sanitizing query: ${query}; ${error.message}`;
    throw error;
  }
};
