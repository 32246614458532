import type { StoreError } from '../../Listings/interfaces/error';
import type { SeoLink } from '../../microservices/msfProductListing/interfaces/processedData';
import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';
import type {
  Category,
  CategoryHubBreadcrumb,
  CategoryHubMetadata,
  CategoryHubModel,
} from '../CategoryHub.model';

export const selectCategoryHub = (
  state: Partial<SpartacuxProductDiscoveryDomainState>,
): CategoryHubModel | undefined => state?.productDiscovery?.categoryHub;

export const selectCategoryHubMetadata = (
  state: SpartacuxProductDiscoveryDomainState,
): CategoryHubMetadata | undefined => selectCategoryHub(state)?.metadata;

export const selectCategoryHubCategory = (
  state: SpartacuxProductDiscoveryDomainState,
): Category | undefined => selectCategoryHubMetadata(state)?.category;

export const selectCategoryHubBreadcrumb = (
  state: SpartacuxProductDiscoveryDomainState,
): CategoryHubBreadcrumb[] | undefined =>
  selectCategoryHubMetadata(state)?.breadcrumb;

export const selectCategoryHubSeoLinks = (
  state: SpartacuxProductDiscoveryDomainState,
): SeoLink[] | undefined => selectCategoryHubMetadata(state)?.seoLinks;

export const selectCategoryHubError = (
  state: SpartacuxProductDiscoveryDomainState,
): StoreError | undefined => selectCategoryHub(state)?.error;

export const selectCategoryHubRewriteURL = (
  state: SpartacuxProductDiscoveryDomainState,
): string | undefined => selectCategoryHub(state)?.rewriteURL;
