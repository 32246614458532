import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Garden: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2BGarden" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1E3C87"
      d="M17.793 10.817H6.243a.565.565 0 00-.452.204.597.597 0 00-.125.49l.362 2.304a.842.842 0 00.794.692h.14L7.625 21a1.16 1.16 0 001.1 1h6.49c.509-.01.95-.364 1.08-.868v-.02l.748-6.593h.096a.857.857 0 00.82-.701l.409-2.309a.577.577 0 00-.119-.493.548.548 0 00-.456-.199z"
    />
    <path
      fill="#F0A22D"
      d="M17.949 3.555a.363.363 0 00-.406-.411c-.871.103-2.512.448-3.654 1.595-1.127 1.132-1.502 2.763-1.625 3.655a.395.395 0 00.443.449c.881-.11 2.488-.462 3.611-1.59 1.145-1.15 1.513-2.817 1.63-3.698zM10.157 7.65a.378.378 0 00.446-.433c-.14-.858-.536-2.364-1.64-3.47-1.168-1.167-2.836-1.594-3.69-1.743a.296.296 0 00-.342.335c.122.83.5 2.432 1.66 3.591 1.112 1.114 2.681 1.554 3.566 1.72z"
    />
  </Svg>
);

Garden.displayName = 'Garden';
