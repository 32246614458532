import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const EnergyRenovation: FunctionComponent<SvgProps> = (props) => (
  <Svg
    optimizationId="iconFamilyB2BEnergyRenovation"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#1E3C87"
      d="M18.1094 2.73448C18.1094 2.28923 17.7494 1.93399 17.3089 1.93399H15.6463C15.2058 1.93399 14.8458 2.28923 14.8458 2.73448V4.64616C14.8458 4.70988 14.8762 4.76979 14.9276 4.80747L17.7911 6.9063C17.9232 7.00313 18.1094 6.90879 18.1094 6.74499V2.73448Z"
    />
    <path
      fill="#F0A22D"
      d="M21.8504 11.3564C21.7096 11.5485 21.4915 11.6502 21.2705 11.6502C21.123 11.6502 20.9745 11.6051 20.8466 11.5113L12.1652 5.15184C11.8134 4.89412 11.3351 4.89412 10.9833 5.15184L2.30192 11.5113C1.98203 11.7457 1.53262 11.6765 1.2981 11.3564C1.06356 11.0363 1.133 10.5869 1.45312 10.3525L11.1498 3.2491C11.4024 3.06393 11.7461 3.06393 11.9986 3.2491L21.6954 10.3525C22.0155 10.5869 22.0849 11.0363 21.8504 11.3564Z"
    />
    <path
      fill="#1E3C87"
      d="M15.1144 7.9015L14.8473 7.70225L12.1642 5.73913C11.8124 5.48171 11.3343 5.48187 10.9827 5.73952L3.6819 11.0886C3.42482 11.277 3.27292 11.5766 3.27292 11.8953V21.1151C3.27292 21.6449 3.69858 22.066 4.22388 22.066H18.9273C19.448 22.066 19.8782 21.6449 19.8782 21.1151V11.8956C19.8782 11.5767 19.7261 11.277 19.4688 11.0887L15.1144 7.9015Z"
    />
    <path
      fill="#F0A22D"
      d="M21.2372 18.7157L23.437 16.5073C23.5137 16.4302 23.5162 16.3064 23.4426 16.2263L22.538 15.2421L21.2372 13.8436C20.9123 13.4987 20.4899 13.3071 20.0458 13.3071L9.22376 13.3071C8.94312 13.3071 8.71561 13.5346 8.71561 13.8153V18.7504C8.71561 19.031 8.94312 19.2585 9.22376 19.2585H20.0458C20.4899 19.2585 20.9123 19.0669 21.2372 18.7157Z"
    />
    <path
      fill="white"
      d="M16.1579 18.6069L15.9289 17.7353H14.4179L14.1824 18.6069H12.8018L14.3192 13.937H15.9957L17.5322 18.6069H16.1579ZM15.668 16.7046L15.4676 15.9411C15.421 15.7715 15.3637 15.552 15.2959 15.2826C15.2301 15.0133 15.1866 14.8203 15.1654 14.7036C15.1463 14.8118 15.1082 14.9899 15.0509 15.2381C14.9958 15.4862 14.8717 15.975 14.6787 16.7046H15.668Z"
    />
  </Svg>
);

EnergyRenovation.displayName = 'Renov';
