import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Painting: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2BPainting" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1E3C87"
      d="M11.187 21.945c.917 0 1.63-.797 1.529-1.708l-.381-3.432a4.24 4.24 0 01.224-1.903l.112-.311a.488.488 0 00-.46-.653H10.2a.488.488 0 00-.45.678 4.24 4.24 0 01.31 2.142l-.4 3.473a1.538 1.538 0 001.528 1.714z"
    />
    <path
      fill="#1E3C87"
      d="M10.595 13.97v-1.237c0-.581.388-1.09.948-1.244l7.677-2.112a.595.595 0 00.438-.573V6.367a.418.418 0 00-.419-.418h-.499l.062-1.308.82.015c.75.013 1.35.625 1.35 1.374v2.964c0 .743-.5 1.394-1.217 1.587l-7.005 1.882a1.29 1.29 0 00-.952 1.154l-.025.353h-1.178zM4.14 5.685h-.565A.577.577 0 013 5.11v-.102c0-.316.259-.575.575-.575h.564c.317 0 .576.259.576.575v.102a.577.577 0 01-.576.575z"
    />
    <path
      fill="#F0A22D"
      d="M17.662 8.275H4.945c-.628 0-1.142-.546-1.142-1.212v-3.85C3.803 2.544 4.317 2 4.945 2h12.717c.628 0 1.142.545 1.142 1.212v3.85c0 .667-.514 1.213-1.142 1.213z"
    />
  </Svg>
);

Painting.displayName = 'Painting';
