import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Plumbing: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2BPlumbing" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1E3C87"
      d="M14.479 16.703a.234.234 0 01.23.25c-.245 2.803-2.565 5-5.389 5-2.983 0-5.403-2.452-5.41-5.481v-4.9c0-.138.11-.25.248-.25h2.906c.137 0 .248.112.248.25v4.896c0 1.127.9 2.041 2.01 2.041.975 0 1.788-.705 1.97-1.64a.227.227 0 01.223-.191l2.964.024zM4.151 9.585h2.92a.242.242 0 00.241-.242V3.656a.242.242 0 00-.242-.242H4.151a.242.242 0 00-.242.242v5.687c0 .134.109.242.242.242z"
    />
    <path
      fill="#F0A22D"
      d="M15.051 15.3h-4.036a.527.527 0 00-.526.531.53.53 0 00.526.531h4.036a.507.507 0 00.37-.155.536.536 0 00.113-.579.522.522 0 00-.483-.327z"
    />
    <path
      fill="#1E3C87"
      d="M14.486 14.928c.154 0 .273-.118.297-.27a2.018 2.018 0 011.984-1.714h2.823a.263.263 0 00.262-.263v-2.93a.262.262 0 00-.262-.263h-2.823c-2.862.003-5.208 2.26-5.4 5.124a.302.302 0 00.303.316h2.816z"
    />
    <path
      fill="#F0A22D"
      d="M7.998 10.829a.531.531 0 00-.373-.906H3.59a.512.512 0 00-.367.155.545.545 0 00-.153.379c0 .291.233.527.52.527h4.035c.14 0 .274-.056.373-.155zM3.59 2.015a.53.53 0 00-.523.53c0 .293.234.53.523.531h4.039a.527.527 0 00.523-.53.527.527 0 00-.523-.531h-4.04zM20.162 8.932v4.101a.532.532 0 00.26.463.515.515 0 00.525 0 .532.532 0 00.26-.463V8.932a.53.53 0 00-.523-.531.528.528 0 00-.522.53z"
    />
  </Svg>
);

Plumbing.displayName = 'Plumbing';
