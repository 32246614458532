import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Tools: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2BTools" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1E3C87"
      d="M19.184 20.472a.516.516 0 01-.305.675l-2.235.809a.53.53 0 01-.674-.311L11.367 8.643a.491.491 0 01.296-.626l1.819-.658c.25-.09.526.034.623.281l5.079 12.832z"
    />
    <path
      fill="#F0A22D"
      d="M13.27 5.106l.356.953a.481.481 0 01-.222.592l.003.008-.07.026-1.544.56-.72.26c-.368.134-.745.033-.84-.22l-.28-.753c-.092.018-.18.046-.27.078-.45.164-.79.495-.976.895a.678.678 0 01-.055.134.483.483 0 01-.313.362l-1.538.563a.495.495 0 01-.632-.292L5.03 5.23a.484.484 0 01.29-.627l1.544-.56a.491.491 0 01.473.082.69.69 0 01.13.067A1.75 1.75 0 008.8 4.25a1.68 1.68 0 00.253-.122l-.14-.376c-.083-.222.076-.49.362-.643.175-.07.348-.147.532-.203 3.976-1.204 6.483-.86 6.483-.86s1.364.026 1.953.935c-1.792-.041-5.347 1.215-4.973 2.125z"
    />
  </Svg>
);

Tools.displayName = 'Tools';
