import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const HomeAppliance: FunctionComponent<SvgProps> = (props) => (
  <Svg
    optimizationId="iconFamilyB2BHomeAppliance"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#F0A22D"
      d="M4.982 5.23a1.06 1.06 0 00-.739-.112l-1.901.406a.432.432 0 00-.246.693l2.774 3.44a47.442 47.442 0 011.626-3.582L4.982 5.23zM3.393 17.573c-.379 0-.64.382-.5.735l1.324 3.38c.274.7.812 1.162 1.417 1.218l5.419.093 5.418-.093c.605-.056 1.143-.518 1.417-1.217l1.325-3.38a.539.539 0 00-.501-.736H3.393zM14.478 4.005c-.73-.693-1.613-1.067-2.518-1.067H10.146c-.907 0-1.789.374-2.52 1.067l3.426.049 3.425-.049z"
    />
    <path
      fill="#1E3C87"
      d="M11.855 2.94L12 2.127a.96.96 0 10-1.892 0l.144.811h1.604zM22.681 13.672l-2.097-4.921a1.701 1.701 0 00-1.565-1.034h-2.62c-1.06-2.318-1.92-3.71-1.92-3.71l-3.426.048-3.425-.048s-.465.751-1.131 2.069A47.494 47.494 0 004.87 9.658a45.391 45.391 0 00-2.037 6.273.806.806 0 00.781.998H18.49c.523 0 .905-.49.782-.998a45.402 45.402 0 00-2.037-6.274h1.17c.276 0 .525.165.633.418l1.845 4.327c.202.476.738.738 1.226.567a.97.97 0 00.572-1.297z"
    />
  </Svg>
);

HomeAppliance.displayName = 'HomeAppliance';
