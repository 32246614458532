import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const Carpentry: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconFamilyB2BCarpentry" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1E3C87"
      d="M7.676 4.252c0-.263.215-.472.486-.472h7.65c.271 0 .486.209.486.472v15.316h.795V3.49a.502.502 0 0 0-.505-.49H7.395a.502.502 0 0 0-.505.49l-.001 16.081h.795l.001-15.319h-.009Z"
    />
    <path fill="#1E3C87" d="M8.312 4.397v15.171h7.35V4.397h-7.35Z" />
    <path
      fill="#fff"
      d="M15.821 3.78H8.162a.476.476 0 0 0-.486.472v15.316h.636V4.397h7.35v15.171h.636V4.252c.009-.254-.215-.472-.477-.472Z"
    />
    <path
      fill="#F0A22D"
      d="M17.794 19.569H6.189a.73.73 0 0 0-.739.716c0 .399.327.716.739.716h11.605a.73.73 0 0 0 .739-.716.723.723 0 0 0-.739-.716ZM15.263 11.929a.82.82 0 0 0-1.636.067.82.82 0 0 0 1.636.068v-.135Z"
    />
  </Svg>
);

Carpentry.displayName = 'Carpentry';
