import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const HomeAndLiving: FunctionComponent<SvgProps> = (props) => (
  <Svg
    optimizationId="iconFamilyB2BHomeAndLiving"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#1E3C87"
      d="M19.202 10.614v1.932c0 .739-.538 1.338-1.2 1.338H5.941c-.663 0-1.2-.599-1.2-1.338v-1.932c0-.744-.542-1.347-1.21-1.347H3.21C2.542 9.267 2 9.87 2 10.614v5.087c0 .744.542 1.348 1.21 1.348l.238 1.615c.033.221.205.384.406.384.202 0 .374-.162.407-.384l.239-1.615h14.944l.239 1.615c.032.221.204.384.406.384.201 0 .373-.162.406-.384l.239-1.615c.668 0 1.21-.604 1.21-1.348v-5.087c0-.744-.542-1.347-1.21-1.347h-.323c-.668 0-1.21.603-1.21 1.347z"
    />
    <path
      fill="#F0A22D"
      d="M18.172 5h-5.443a.536.536 0 00-.536.536v7.398c0 .296.24.536.536.536H18a.757.757 0 00.758-.758v-2.236c0-.513.232-.974.603-1.279.314-.257.476-.655.476-1.06v-1.47c0-.92-.746-1.667-1.666-1.667zM11.214 13.47c.296 0 .536-.24.536-.536V5.536A.536.536 0 0011.214 5H5.772c-.92 0-1.667.746-1.667 1.667v1.69c0 .326.179.61.437.81.393.303.643.778.643 1.31v2.235c0 .419.339.758.757.758"
    />
  </Svg>
);

HomeAndLiving.displayName = 'HomeAndLiving';
